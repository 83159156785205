exports.components = {
  "component---src-layouts-currency-exchange-rates-converter-index-tsx": () => import("./../../../src/layouts/currency-exchange-rates-converter/index.tsx" /* webpackChunkName: "component---src-layouts-currency-exchange-rates-converter-index-tsx" */),
  "component---src-layouts-dataset-detail-dataset-detail-jsx": () => import("./../../../src/layouts/dataset-detail/dataset-detail.jsx" /* webpackChunkName: "component---src-layouts-dataset-detail-dataset-detail-jsx" */),
  "component---src-layouts-explainer-explainer-tsx": () => import("./../../../src/layouts/explainer/explainer.tsx" /* webpackChunkName: "component---src-layouts-explainer-explainer-tsx" */),
  "component---src-layouts-insight-insight-tsx": () => import("./../../../src/layouts/insight/insight.tsx" /* webpackChunkName: "component---src-layouts-insight-insight-tsx" */),
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-about-us-about-section-about-section-jsx": () => import("./../../../src/pages/about-us/about-section/about-section.jsx" /* webpackChunkName: "component---src-pages-about-us-about-section-about-section-jsx" */),
  "component---src-pages-about-us-contact-section-contact-section-jsx": () => import("./../../../src/pages/about-us/contact-section/contact-section.jsx" /* webpackChunkName: "component---src-pages-about-us-contact-section-contact-section-jsx" */),
  "component---src-pages-about-us-faq-section-faq-section-jsx": () => import("./../../../src/pages/about-us/faq-section/faq-section.jsx" /* webpackChunkName: "component---src-pages-about-us-faq-section-faq-section-jsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-americas-finance-guide-index-jsx": () => import("./../../../src/pages/americas-finance-guide/index.jsx" /* webpackChunkName: "component---src-pages-americas-finance-guide-index-jsx" */),
  "component---src-pages-api-documentation-index-jsx": () => import("./../../../src/pages/api-documentation/index.jsx" /* webpackChunkName: "component---src-pages-api-documentation-index-jsx" */),
  "component---src-pages-datasets-index-jsx": () => import("./../../../src/pages/datasets/index.jsx" /* webpackChunkName: "component---src-pages-datasets-index-jsx" */),
  "component---src-pages-downloads-index-jsx": () => import("./../../../src/pages/downloads/index.jsx" /* webpackChunkName: "component---src-pages-downloads-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-release-calendar-index-jsx": () => import("./../../../src/pages/release-calendar/index.jsx" /* webpackChunkName: "component---src-pages-release-calendar-index-jsx" */)
}

